.nav-area {
    padding: 5px;
    justify-content: space-between;
    border-bottom: 1.2px solid rgb(70, 69, 69);
    background-color: rgb(240, 244, 244);
}

.nav-profile {
    margin-left: 5px;
    margin-right: 10px;
}

.nav-logo {
    height: 65px;
    width: 110px;
    border-radius: 10px;
}

.nav-name {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    color: rgb(65, 64, 64);
    font-size: 35px;
    font-weight: 500;
}

.nav-links {
    display: flex;
    list-style: none;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
}

.nav-text {
    margin: 10px;
    text-decoration: none;
    color: rgb(65, 64, 64);
    font-size: 20px;
    font-weight: 500px;
}

@media screen and (max-width: 900px) {
    .nav-links {
        display: none;
    }

    .nav-area {
        display: flex;
        justify-content: center;
    }

    .nav-profile {
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 0px;
    }

    .nav-name {
        font-size: 30px;
    }

    .nav-logo {
        height: 52px;
        width: 88px;
        }
}