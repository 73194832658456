.projects-area {
    padding-top: 75px;
    background-color: #5266c2;
}

.project-list {
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.projects-header {
    font-size: 40px;
    font-weight: 600;
    justify-content: center;
    margin-bottom: 30px;
    text-decoration: underline;
    color: aliceblue;
}

.project-box {
    background-color: white;
    margin-bottom: 50px;
    box-shadow: 1px 1px 25px rgb(63, 62, 62);
    border-radius: 30px;
}

.project-photo{
    width: 300px;
    border-radius: 30px;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.project-text-area {
    flex-direction: column;
    align-items: center;
}

.project-title {
    font-size: 30px;
    text-align: center;
}

.project-description {
    font-size: 18px;
    max-width: 500px;
    padding: 20px;
    text-align: center;
}

.github-logo{
    margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
    .projects-header {
        font-size: 35px;
    }

    .projects-area {
        padding-top: 50px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .project-box {
        flex-direction: column;
        align-items: center;
    }

    .project-photo {
        margin-left: auto;
        margin-right: auto;
    }
    
}