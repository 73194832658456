.experience-area {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
}

.experience-heading {
    font-size: 40px;
    font-weight: 600;
    justify-content: center;
    margin-bottom: 30px;
    text-decoration: underline;
}

.experience-item {
    justify-content: center;
    margin-bottom: 50px;
}

.experience-photo {
    min-height: 150px;
    max-height: 250px;
    width: 250px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 10px;
    border-radius: 30px;
    box-shadow: 1px 1px 20px rgb(63, 62, 62);
}

.experience-title {
    justify-content: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 5px;
}

.job-title {
    font-size: 25px;
    font-style: italic;
}

.experience-date {
    font-size: 20px;
    font-style: italic;
    line-height: 20px;
    margin-bottom: 5px;
}

.experience-info {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 50px;
}

.experience-description {
    font-size: 18px;
    max-width: 700px;
}

@media screen and (max-width: 900px) {
    .experience-item {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: 75px;
    }

    .experience-info {
        margin: 0px;
    }

    .experience-photo {
        margin-left: 0px;
        margin-right: 0px;
        align-self: center;
        margin-bottom: 20px;
    }

    

    .experience-heading {
        font-size: 35px;
    }
}