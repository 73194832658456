.about-me-area {
    background: linear-gradient(320deg, #325dba, #99adb3);
    padding-top: 75px;
    padding-bottom: 50px;
}

.picture-area {
    justify-content: center;
}

.profile-photo {
    width: 250px;
    border-radius: 150px;
    box-shadow: 1px 1px 40px rgb(63, 62, 62);
}

.about-me-header {
    justify-content: center;
    text-align: center;
    font-size: 80px;
    font-weight: 600;
    color: whitesmoke;
    text-shadow: 2px 2px 10px rgb(63, 62, 62);
}

.type-area {
    justify-content: center;
    text-align: center;
    font-size: 40px;
    font-weight: 300;
    color: whitesmoke;
    text-shadow: 1px 1px 2px rgb(63, 62, 62);
}

.about-me-body {
    justify-content: center;
    font-size: 18px;
    font-weight: 300;
    color: whitesmoke;
    text-shadow: 1px 1px 4px rgb(63, 62, 62);
    margin-top: 20px;
    margin-right: 200px;
    margin-left: 200px;
    text-align: center;
}

@media screen and (max-width: 900px) {
    .about-me-body {
        margin-left: 50px;
        margin-right: 50px;
    }
    
    .about-me-area {
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .profile-photo {
        width: 200px;
    }

    .about-me-header {
        font-size: 60px;
    }

    .type-area {
        font-size: 30px;
    }

    .about-me-body {
        font-size: 18px;
    }

    .about-me-header {
        margin-left: 5px;
        margin-right: 5px;
    }
}
